<template>
  <div>
    <van-popup
      v-model="_show"
      round
      position="bottom"
    >
      <div class="container">
        <p>确认收到货了吗</p>
        <p><img style="width: 90px;" :src="picUrl" alt=""></p>
        <p>为了保证弄的售后权益，请收到商</p>
        <p>品确认无误后再确认收货</p>
        <van-button 
          style="margin-bottom: 25px;" 
          round 
          size="large" 
          color="#FB2B53"
          @click="dealSuccess"
        >确认</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { receive } from '@/api/tsMallorderDetail'
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String
    },
    picUrl: {
      type: String
    }
  },

  data () {
    return {
    }
  },

  computed: {
    _show : {
      get () {
        return this.show
      },
     set () {
        this.$emit('setShow', false)
      }
    }
  },

 methods: {
    dealSuccess () {
      receive(this.id).then(res => {
        if (res.data.code === 0) {
          this.$emit('confirmOrder', false)
          this.$router.push('/dealSuccess')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 0 15px;
  font-size: 14px;
  color: #333;
  p {
    text-align: center;
  }
}
.container p:nth-of-type(1) {
  margin: 20px 0 30px;
}
img {
  margin-bottom: 10px ;
}
.container p:nth-of-type(4) {
  margin-bottom: 30px;
}
.van-button--large {
  height: 40px;
}
</style>