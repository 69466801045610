<template>
  <div class="header" v-show="isShow">
    <van-nav-bar
      title="订单详情"
      left-arrow
      fixed
      placeholder
      :border="false"
      @click-left="rollback"
      :z-index="9999"
    />

    <div class="dealSuccess" v-if="detail.status == '4'">
      <van-icon size="19px" name="checked" color="#3E85FF" />
      <span>交易完成</span>
    </div>

    <!-- 取消订单5  退款成功、退货退款成功、取消交易-->
    <div class="dealSuccess" v-if="detail.status == '5'">
      <van-icon size="19px" name="checked" color="#3E85FF" />
      <span>交易关闭</span>
    </div>

    <!-- 待付款0 已发货（待收货）2-->
    <div class="icons clock" v-if="detail.status == '0'">
      <img src="./tsmall/clock.png" alt="" />
      <div class="icons-infomation">
        <p>等待买家付款</p>
        <p>{{ createTime }}</p>
      </div>
    </div>

    <div class="icons clock" v-if="detail.status == '1'">
      <img src="./tsmall/clock.png" alt="" />
      <div class="icons-infomation">
        <p>待发货</p>
        <p>商家正在备货中</p>
      </div>
    </div>

    <!-- 待付款0 待发货 1 -->
    <div
      class="icons location"
      v-if="detail.status == '0' || detail.status == '1'"
    >
      <img src="./tsmall/location.png" alt="" />
      <div class="icons-infomation">
        <p>
          <span style="margin-right: 18px">{{
            detail.orderLogistics.userName
          }}</span>
          <span>{{ detail.orderLogistics.telNum }}</span>
        </p>
        <p>{{ detail.orderLogistics.address }}</p>
      </div>
    </div>

    <div class="icons clock" v-if="detail.status == '2'">
      <img src="./tsmall/clock.png" alt="" />
      <div class="icons-infomation">
        <p>已发货</p>
        <p>{{ deliveryDate }}</p>
      </div>
    </div>

    <!-- 待收货(已发货) 2 -->
    <div class="Ge-step" v-if="detail.status == '2' || detail.status == '4'">
      <van-steps active-color="#38f" direction="vertical" :active="4">
        <van-step>
          <div class="Ge-step-box">
            <div class="Ge-step-box-step">
              <p style="color: #333">
                物流状态
                {{
                  expressStatus == "0"
                    ? "已下单"
                    : expressStatus == "1"
                    ? "揽收"
                    : expressStatus == "2"
                    ? "疑难"
                    : expressStatus == "3"
                    ? "签收"
                    : expressStatus == "4"
                    ? "退签"
                    : expressStatus == "5"
                    ? "派件"
                    : expressStatus == "8"
                    ? "清关"
                    : expressStatus == "14"
                    ? "拒签"
                    : "暂无状态"
                }}
              </p>
              <p class="Ge-step-box-step-p" style="color: #999">
                {{ expressCurrentStatus.time }}
                {{ expressCurrentStatus.context }}
              </p>
            </div>
            <van-icon
              class="Ge-step-box-arrow"
              size="18"
              name="arrow"
              @click="enterLogistics"
              color="#999"
            />
          </div>
        </van-step>
        <van-step>
          <div class="Ge-step-box">
            <div class="Ge-step-box-step">
              <p style="color: #333">
                {{ detail.orderLogistics.userName }}&nbsp;&nbsp;{{
                  detail.orderLogistics.telNum
                }}
              </p>
              <p class="Ge-step-box-step-p" style="color: #999">
                {{ detail.orderLogistics.address }}
              </p>
            </div>
          </div>
        </van-step>
      </van-steps>
    </div>

    <div class="commodity">
      <div class="commodity-store">
        <div class="commodity-store-infomation">
          <van-image
            round
            width="25px"
            height="25px"
            :src="detail.shopInfo.imgUrl"
          />
          <span>{{ detail.shopInfo.name }}</span>
          <van-icon name="arrow" size="13" />
        </div>

        <div v-for="item in detail.listOrderItem" :key="item.id">
          <div class="commodity-store-shops">
            <img :src="item.picUrl" alt="" />
            <div class="commodity-store-shops-message">
              <div class="commodity-store-shops-message-name ellipsis">
                <span>{{ item.spuName }}</span>
                <span>￥{{ item.salesPrice.toFixed(2) }}</span>
              </div>
              <div
                class="commodity-store-shops-message-name"
                style="color: #999"
              >
                <span
                  >规格：{{
                    item.specInfo == "null" || item.specInfo == null
                      ? "统一规格"
                      : item.specInfo
                  }}</span
                >
                <span>x{{ item.quantity }}</span>
              </div>
            </div>
          </div>

          <!-- 待发货 2 -->
          <div class="commodity-store-button">
            <span></span>
            <span>
              <van-button
                v-if="
                  (detail.status == '2' && item.status == '0') ||
                  (detail.status == '4' && item.status == '0')
                "
                style="padding: 0 25px"
                size="mini"
                round
                type="default"
                @click="applyAfter"
                >申请售后</van-button
              >
              <van-button
                v-if="detail.status == '1' && item.status == '0'"
                style="padding: 0 25px"
                size="mini"
                round
                type="default"
                @click="refund"
                >申请退款</van-button
              >
              <div class="div" v-if="item.status != '0'">
                {{
                  item.status == "1"
                    ? "退款中"
                    : item.status == "2"
                    ? "退货退款中"
                    : item.status == "3"
                    ? "完成退款"
                    : item.status == "4"
                    ? "完成退货退款"
                    : ""
                }}
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="describe">
        <div class="describe-price">
          <p>
            <span>商品总价</span>
            <span>￥{{ detail.totalPrices.toFixed(2) }}</span>
          </p>
          <p>
            <span>运费</span>
            <span>￥{{ detail.freightPrice.toFixed(2) }}</span>
          </p>
          <p>
            <span>优惠券</span>
            <span
              >{{ detail.couponPrice.toFixed(2) == 0 ? "" : "-" }}￥{{
                detail.couponPrice.toFixed(2) == 0
                  ? 0
                  : detail.couponPrice.toFixed(2)
              }}</span
            >
          </p>
          <p>
            <span>实付</span>
            <span>￥{{ detail.paymentPrice.toFixed(2) }}元</span>
          </p>
        </div>

        <div class="describe-message">
          <p v-if="detail.status == '5'">
            <span>收货信息</span>
            <span style="width: 74%; text-align: end">{{
              detail.orderLogistics.address
            }}</span>
          </p>
          <p>
            <span>订单编号</span>
            <span>
              <span>{{ detail.orderNo }}</span>
              <span class="span" @click="clipBoard(detail.orderNo)">复制</span>
            </span>
          </p>
          <p>
            <span v-if="detail.status != 0">交易号</span>
            <span>{{ detail.transactionId }}</span>
          </p>
          <p
            v-if="
              detail.status == '1' ||
              detail.status == '2' ||
              detail.status == '4' ||
              detail.status == '5'
            "
          >
            <span>下单时间</span>
            <span>{{ detail.createTime }}</span>
          </p>
          <p v-if="detail.status == '0'">
            <span>创建时间</span>
            <span>{{ detail.createTime }}</span>
          </p>
          <p
            v-if="
              detail.status == '1' ||
              detail.status == '2' ||
              detail.status == '4' ||
              detail.status == '5'
            "
          >
            <span>支付方式</span>
            <span>{{
              detail.paymentType == "1"
                ? "微信支付"
                : detail.paymentType == "2"
                ? "支付宝支付"
                : ""
            }}</span>
          </p>
          <p v-if="detail.status == '4'">
            <span>发货时间</span>
            <span>{{ detail.deliveryTime }}</span>
          </p>
          <p v-if="detail.status == '4'">
            <span>成交时间</span>
            <span>{{ detail.closingTime }}</span>
          </p>
          <p>
            <span>备注</span>
            <span>{{
              detail.userMessage ? detail.userMessage : "暂无备注"
            }}</span>
          </p>
        </div>

        <div class="order-button">
          <div class="order-button-first" v-if="detail.status == '0'">
            <span></span>
            <div>
              <van-button round style="margin-right: 10px" @click="cancal"
                >取消订单</van-button
              >
              <van-button round type="danger" @click="paymaentOrder"
                >付款</van-button
              >
            </div>
          </div>

          <!-- 已发货 2 -->
          <div class="order-button-second" v-if="detail.status == '2'">
            <span></span>
            <div>
              <van-button
                round
                style="margin-right: 10px"
                @click="enterLogistics"
                >查看物流</van-button
              >
              <van-button round type="danger" @click="comfirm"
                >确认收货</van-button
              >
            </div>
          </div>

          <div class="order-button-third" v-if="detail.status == '4'">
            <van-button round @click="deletOrder">删除订单</van-button>
            <van-button round @click="enterLogistics">查看物流</van-button>
            <van-button round type="danger" @click="evaluate">
              {{
                detail.appraisesStatus == "0"
                  ? "去评价"
                  : detail.appraisesStatus == "1"
                  ? "已评价"
                  : "已追评"
              }}
            </van-button>
          </div>
        </div>
      </div>

      <!-- 确认收货 -->
      <comfirm
        @setShow="setShow"
        :show="show"
        :id="receiveId"
        :picUrl="picUrl"
        @confirmOrder="confirmOrder"
      />

      <!-- 取消订单 -->
      <cancalOrder
        @setCancalOrder="setCancalOrder"
        :cancalOrder="cancalOrder"
        :id="id"
        @refreshPage="refreshPage"
      />

      <!-- 付款 -->
      <paymentPopup
        @setPaymentPopup="setPaymentPopup"
        :paymentPopup="paymentPopup"
        :payId="payId"
        :payPrice="payPrice"
      />

      <!-- 删除订单 -->
      <deleteOrder
        @setDeleteOrder="setDeleteOrder"
        :deleteOrder="deleteOrder"
        :deteleId="deteleId"
        @refresDeteleOrder="refresDeteleOrder"
      />
    </div>
  </div>
</template>

<script>
import comfirm from "./components/comfirm";
import cancalOrder from "./components/cancalOrder";
import deleteOrder from "./components/deleteOrder";
import paymentPopup from "./components/paymentOrder";
import {
  orderDetail,
  queryExpress,
  cancalOrders,
  receive,
} from "@/api/tsMallorderDetail";
import ClipBoard from "clipboard";
import { mapMutations } from "vuex";
import { Toast } from "vant";
export default {
  components: {
    comfirm,
    cancalOrder,
    deleteOrder,
    paymentPopup,
  },

  data() {
    return {
      isShow: false,
      show: false,
      cancalOrder: false,
      deleteOrder: false,
      paymentPopup: false,
      detail: {},
      id: "",
      deteleId: "",
      orderType: "", // 删除订单类型
      expressStatus: "", // 快递初始状态
      expressCurrentStatus: {}, // 快递当前状态
      receiveId: "", // 收货id
      picUrl: "",
      payPrice: 0, // 付款价格
      createTime: "", // 自动关闭
      timer: null,
      deliveryDate: "", // 自动收货
      timer2: null,
      payId: "", // 付款id
    };
  },

  created() {
    this.$store.commit(
      "setSession",
      this.$route.query.session ||
        "$2a$10$zHqkJtKNMWWwR7X8wlKKuesPzM8xzVDjgW2RrM5unTeC2GeP0Zpf."
    );
    this.orderDetail();
  },

  methods: {
    ...mapMutations(["shopCar/set_commentOrderInfo"]),

    refund() {
      this.$router.push({
        path: "/zirefund",
        query: {
          data: {
            orderType: this.detail.orderType,
            refundAmount: this.detail.paymentPrice,
            freightPrice: this.detail.freightPrice,
            orderId: this.detail.id,
          },
        },
      });
    },

    // 申请售后
    applyAfter() {
      this.$router.push({
        path: "/applySale",
        query: {
          id: this.detail.id,
          first: 0,
          session: this.$route.query.session,
        },
      });
    },

    rollback() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },

    checkLogistics() {
      this.$router.push("/checkLogistics");
    },

    // 确认收货
    comfirm() {
      this.show = true;
      this.receiveId = this.detail.id;
      this.picUrl = this.detail.listOrderItem[0].picUrl;
    },

    setShow(val) {
      this.show = val;
    },

    // 确认收货 刷新
    confirmOrder(val) {
      this.show = val;
      this.orderDetail();
    },

    // 取消订单
    cancal() {
      this.cancalOrder = true;
      this.id = this.detail.id;
    },

    setCancalOrder(val) {
      this.cancalOrder = val;
    },

    // 取消订单 刷新
    refreshPage(val) {
      this.cancalOrder = val;
      this.orderDetail();
    },

    // 删除订单
    deletOrder() {
      this.deleteOrder = true;
      this.deteleId = this.detail.id;
    },

    setDeleteOrder(val) {
      this.deleteOrder = val;
    },

    // 删除订单刷新
    refresDeteleOrder(val) {
      this.deleteOrder = val;
      // 删除订单后跳返回上一页
      setTimeout(() => {
        this.rollback();
      }, 1000);
    },

    // 付款
    paymaentOrder() {
      this.paymentPopup = true;
      this.payPrice = this.detail.paymentPrice;
      this.payId = this.detail.id;
    },

    setPaymentPopup(val) {
      this.paymentPopup = val;
    },

    orderDetail() {
      // orderDetail('1556838109737570305').then(res => { // 1544225070479745025 1544226770254995458 1556838109737570305
      orderDetail(this.$route.query.id).then((res) => {
        if (res.data.code === 0) {
          this.detail = res.data.data;
          let totalPrices = 0;
          res.data.data.listOrderItem.forEach((x) => {
            totalPrices =
              totalPrices + Number((x.salesPrice * x.quantity).toFixed(2));
          });

          // 总价
          this.detail.totalPrices = totalPrices;
          let obj = {
            orderId: this.detail.id,
            shopId: this.detail.shopId,
            listOrderItem: this.detail.listOrderItem,
          };

          // 优惠券
          this.detail.couponPrice =
            Number(totalPrices) +
            Number(this.detail.freightPrice) -
            Number(this.detail.paymentPrice);

          // 物流
          this["shopCar/set_commentOrderInfo"](JSON.parse(JSON.stringify(obj)));
          if (
            res.data.data.status == "2" ||
            res.data.data.status == "3" ||
            res.data.data.status == "4"
          ) {
            const data = {
              logistics: res.data.data.orderLogistics.logistics,
              logisticsNo: res.data.data.orderLogistics.logisticsNo,
            };
            this.queryExpress(data);
          }

          // 自动取消订单
          if (res.data.data.status == "0") {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
              const time =
                new Date(
                  res.data.data.createTime.replace(/-/g, "/")
                ).getTime() + 86400000;
              this.countDown(time);
            }, 1000);
          }

          // 自动确认收货
          if (res.data.data.status == "2") {
            const time =
              new Date(
                res.data.data.deliveryTime.replace(/-/g, "/")
              ).getTime() +
              15 * 24 * 60 * 60 * 1000;
            clearInterval(this.timer2);
            this.timer2 = setInterval(() => {
              this.deliveryTime(time);
            }, 1000);
          }

          this.isShow = true;
        }
      });
    },

    countDown(time) {
      const nowTime = Date.now();
      let diff = (time - nowTime) / 1000;
      if (diff <= 0) {
        clearInterval(this.timer);
        cancalOrders({
          id: this.detail.id,
          calmsg: "订单未支付 已过期",
        }).then((res) => {
          if (res.data.code === 0) {
            clearInterval(this.timer);
            Toast("订单已取消");
            this.orderDetail();
          }
        });
        return;
      }
      const hours = parseInt(diff / 3600);
      diff = diff - hours * 3600;
      const minutes = parseInt(diff / 60);
      diff = diff - minutes * 60;
      const second = parseInt(diff);
      this.createTime = `剩${hours}小时${minutes}分${second}秒自动关闭`;
    },

    // 自动收货
    deliveryTime(time) {
      const currentTime = Date.now();
      let diff = (time - currentTime) / 1000;
      if (diff < 0) {
        clearInterval(this.timer2);
        receive(this.detail.id).then((res) => {
          if (res.data.code === 0) {
            clearInterval(this.timer2);
            Toast.success("自动确认收货");
            this.orderDetail();
          }
        });
        return;
      }
      const days = parseInt(diff / (24 * 60 * 60));
      diff = diff - days * 24 * 60 * 60;
      const hours = parseInt(diff / 3600);
      diff = diff - hours * 3600;
      const minutes = parseInt(diff / 60);
      diff = diff - minutes * 60;
      const second = parseInt(diff);
      this.deliveryDate = `${days}天${hours}小时${minutes}分${second}秒后自动确认收货`;
    },

    // 复制
    clipBoard(orderNo) {
      const clipBoard = new ClipBoard(".span", {
        text: () => {
          return orderNo;
        },
      });
      clipBoard.on("success", () => {
        this.$toast("复制成功");
        clipBoard.destroy();
      });
      clipBoard.on("error", () => {
        this.$toast("复制失败");
      });
    },

    // 实时物流
    queryExpress(data) {
      queryExpress(data).then((res) => {
        if (res.data.code === 0) {
          this.expressStatus = res.data.data.state;
          this.expressCurrentStatus = res.data.data.data[0];
        }
      });
    },

    // 进入物流详情
    enterLogistics() {
      const picUrl = this.detail.listOrderItem.map((x) => x.picUrl);
      const data = {
        logistics: this.detail.orderLogistics.logistics,
        logisticsNo: this.detail.orderLogistics.logisticsNo,
        picUrl,
        logisticsDesc: this.detail.orderLogistics.logisticsDesc,
      };
      this.$router.push({
        path: "/checkLogistics",
        query: {
          data,
          info: {
            userName: this.detail.orderLogistics.userName,
            telNum: this.detail.orderLogistics.telNum,
            address: this.detail.orderLogistics.address,
          },
        },
      });
    },

    // 去评价
    evaluate() {
      if (this.detail.appraisesStatus == "0") {
        this.$router.push("/commentOrder");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  background: white;
}
/deep/ .van-nav-bar__left {
  padding: 0 5px;
}
.nav {
  padding-left: 10px;
  &-title {
    font-size: 18px;
  }
}
.icons {
  padding: 15px 0;
  display: flex;
  padding-left: 10px;
  img {
    width: 19px;
    height: 19px;
    margin-top: 2px;
  }
  &-infomation {
    flex: 1;
    margin-left: 11px;
    font-size: 14px;
  }
  &-infomation p:nth-of-type(1) {
    color: #333;
  }
  &-infomation p:nth-of-type(2) {
    color: #666;
  }
}
.dealSuccess,
.dealClose {
  display: flex;
  align-items: center;
  padding: 13px 0 13px 10px;
  span {
    font-size: 14px;
    color: #333;
    margin-left: 11px;
  }
}
.clock,
.location,
.dealSuccess,
.dealClose {
  border-top: 1px solid #ebedf0;
  border-bottom: 1px solid #ebedf0;
}
.commodity {
  margin-top: 20px;
  padding: 0 23px 0 23px;
  &-store-infomation {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    span {
      margin: 0 0 0 8px;
    }
  }
  &-store-shops {
    margin-bottom: 10px;
    font-size: 14px;
    img {
      width: 90px;
      height: 90px;
    }
    display: flex;
    &-message {
      flex: 1;
      margin-left: 15px;
      &-name {
        display: flex;
        justify-content: space-between;
      }
    }
    .ellipsis {
      margin-bottom: 15px;
    }
    &-message .ellipsis span:nth-of-type(1) {
      width: 56.8%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &-store-button {
    margin: 12px 0 19px;
    display: flex;
    justify-content: space-between;
    .div {
      width: 100px;
      height: 22px;
      font-size: 14px;
      border: 1px solid #ebedf0;
      border-radius: 999px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.describe {
  color: #333;
  font-size: 14px;
  p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
  }
  &-price {
    margin-bottom: 9px;
  }
  &-price p:nth-of-type(3) span:nth-of-type(2) {
    color: red;
  }
  &-price p:nth-of-type(4) span:nth-of-type(2) {
    color: #fe2955;
  }
  &-message {
    border-top: 1px solid #e5e5e5;
    padding-top: 9px;
  }
  &-message .span {
    color: red;
    text-decoration: underline;
    margin-left: 5px;
  }
  &-message .span:active {
    color: #333;
  }
}
.order-button {
  border-top: 1px solid #e5e5e5;
  .van-button {
    width: 105px;
    height: 30px;
  }
  &-first,
  &-second {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }
  &-third {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }
}
.Ge-step {
  border-top: 1px solid #ebedf0;
  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-arrow:active {
      background: #ddd;
    }
    &-step {
      width: 90%;
      &-p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>