<template>
  <div>
    <van-popup
      v-model="_cancalOrder"
      round
      position="bottom"
    >
      <div class="container">
        <div class="container-title">
          <p>订单取消</p>
          <p>取消后无法回退优惠券退回，有效期内使用</p>
        </div>

        <div class="reason">
          <p v-for="(item, index) in reason" :key="index" @click="selectReason(index, item)">
            <van-icon style="margin-right: 10px;" v-show="index !== index2" name="checked"></van-icon>
            <van-icon style="margin-right: 10px;" v-show="index === index2" color="#FB2B53" name="checked"></van-icon>
            <span>{{ item }}</span>
          </p>
        </div>

        <div class="container-button">
          <van-button @click="$emit('setCancalOrder', false)" round>暂不取消</van-button>
          <van-button @click="cancal" round color="#FB2B53">确定取消</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { cancalOrders } from '@/api/tsMallorderDetail'
  export default {
    props: {
      cancalOrder: {
        type: Boolean,
        required: true
      },
      id : {
        type: String
      }
    },

    computed:{
      _cancalOrder: {
        get () {
          return this.cancalOrder
        },
        set () {
          this.$emit('setCancalOrder', false)
        }
      }
    },

    data () {
      return {
        reason: [
          '请选择取消原因', '几个有点贵', '规格/款式/数量排错',
          '收货地址错了', '暂时不需要了', '其它'
        ],
        index2: -1,
        cancalReason: ''
      }
    },

    methods: {
      selectReason (index, item) {
        this.index2 = index
        this.cancalReason = item
      },

      cancal () {
        if (this.index2 == -1) {
          Toast('请选择取消原因')
          return
        }
        const data  = {
          id: this.id,
          calmsg: this.cancalReason
        }
        cancalOrders(data).then(res => {
          if (res.data.code === 0) {
            Toast('订单已取消')
            this.$emit('refreshPage', false)
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.container {
  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 20px;
  }
  &-title p:nth-of-type(1) {
    font-size: 14px;
    color: #333;
  }
  &-title p:nth-of-type(2) {
    font-size: 12px;
    color: #999;
  }
  &-button {
    padding: 15px 0;
    display: flex;
    justify-content: space-evenly;
    .van-button {
      width: 105px;
      height: 30px;
    }
  }
}
.reason {
  font-size: 14px;
  color: #333;
  p {
    padding: 12px 18px;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
  }
  p:active {
    background: #E5E5E5;
  }
}
</style>