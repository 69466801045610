<template>
  <div>
    <van-popup
      v-model="_paymentPopup"
      round
      position="bottom"
      closeable
      close-icon="close"
    >
      <div style="padding: 40px 13px 0;">
        <p class="title">
          <span class="title-left">￥</span>
          <span class="title-right">{{ payPrice }}</span>
        </p>
        <!-- <div class="payType" @click="selectPayment(1)">
          <div class="payType-left">
            <img src="../tsmall/weixin.png" alt="">
            <span>微信支付</span>
          </div>
          <van-icon name="checked" size="20" :color="sign == 1 ? '#fc2b53' : '#b6b6b6'"/>
        </div> -->
        <div class="payType" @click="selectPayment(2)">
          <div class="payType-left">
            <img style="width: 15px;" src="../tsmall/zhifubao.png" alt="">
            <span>支付宝支付</span>
          </div>
          
          <van-icon name="checked" size="20" :color="sign == 2 ? '#fc2b53' : '#b6b6b6'"/>
        </div>
        <!-- <div class="payType" @click="selectPayment(3)">
          <div class="payType-left">
            <img style="width: 15px;" src="../tsmall/qianbao.png" alt="">
            <span>余额支付</span>
          </div>
          <van-icon name="checked" size="20" :color="sign == 3 ? '#fc2b53' : '#b6b6b6'"/>
        </div> -->

        <div class="payment-button">
          <van-button :disabled="disabled" block round color="#fc2b53" @click="payment">确认付款</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import payment from '@/common/order'
export default {
  props: {
    paymentPopup: {
      type: Boolean,
      required: true
    },

    payPrice: {
      type: Number,
      required: true
    },

    payId: {
      type: String
    }
  },

  data () {
    return {
      sign: 0
    }
  },
   computed: {
    _paymentPopup: {
      get () {
        return this.paymentPopup
      },
      set () {
        this.$emit('setPaymentPopup', false)
        this.sign = 0
      }
    },

    disabled () {
      let disabled = false
      if (this.sign == 0) {
        disabled = true
      } else {
        disabled = false
      }
      return disabled
    }
  },

  methods: {
    selectPayment (sign) {
      this.sign = sign
    },

    payment () {
      payment(this.payId, this.sign)
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: #F52556;
  text-align: center;
  margin-bottom: 25px;
  &-left {
    font-size: 12px;
  }
  &-right {
    font-size: 18px;
    font-weight: bold;
  }
}
.payType {
  border-bottom: 1px solid #F6F6F6;
  padding: 11px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-left {
    display: flex;
    align-items: center;
  }
  span {
    font-size: 14px;
    color: #333;
  }
  img {
    width: 17px;
    margin-right: 10px;
  }
}
.payment-button {
  .van-button {
    height: 40px;
  }
  padding: 40px 0 20px;
}

</style>