<template>
  <div>
    <van-overlay :show="_deleteOrder" @click="closeOverlay">
      <div class="wrapper">
        <div class="block">
          <p>确认删除订单?</p>
          <div class="block-button">
            <van-button round @click="closeOverlay">取消</van-button>
            <van-button round color="#FB2B53" @click="confirm">确定</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { deleteOrder } from '@/api/tsMallorderDetail'
import { Toast} from 'vant'
export default {
  props: {
    deleteOrder: {
      type: Boolean,
      required: true
    },

    deteleId: {
      type: String
    }
  },
  
  computed: {
    _deleteOrder: {
      get () {
        return this.deleteOrder
      },
      set () {
        this.$emit('setDeleteOrder', false)
      }
    }
  },

  methods: {
    closeOverlay () {
      this.$emit('setDeleteOrder', false)
    },

     confirm () {
      deleteOrder({
        id: this.deteleId,
        type: 1
      }).then(res => {
        if (res.data.code === 0) {
          Toast.success('删除成功')
           this.$emit('refresDeteleOrder', false)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  min-width: 300px;
  border-radius: 8px;
  height: 165px;
  background-color: #fff;
  p {
    font-size: 14px;
    text-align: center;
    padding: 40px 0;
    
  }
  .van-button {
    height: 30px;
    width: 100px;
  }
  &-button {
    display: flex;
    justify-content: space-evenly;
  }
}
</style>