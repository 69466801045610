import { openPay } from './index'
import { weixin, zhifubao } from '@/api/tsMallorderDetail'

const payment = (id, type) => {
  if (type == '1') {
    weixin({ tradeType: 'APP', id}).then(res => {
      if (res.data.code === 0) {
        openPay(res, '1')
      }
    })
  } else if (type == '2') {
    zhifubao({ id }).then(res => {
      if (res.data.code === 0) {
        openPay(res.data, '2')
      }
    })
  }
}

export default payment